/* eslint-disable react/no-array-index-key */
import React, { useState, useLayoutEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import ButtonMinimal from 'components/elements/ButtonMinimal'
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'

const StyledBestBlogs = styled.div``

const StyledButtonMinimal = styled(ButtonMinimal)`
  background: #fff;
`

const InnerBlogs = styled.div`
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 40px 30px 5px 30px;
  background-color: ${(props) => props.theme.color.face.light};
  max-width: 417px;
  min-width: 375px;

  h2 {
    font-size: ${(props) => props.theme.font.size.xml};
    font-weight: ${(props) => props.theme.font.weight.xl};
  }
`

const BlogShadow = styled(motion.div)`
  z-index: -1;
  position: absolute;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.color.gradient.main};
`

const Post = styled(motion.div)`
  h3 {
    font-size: ${(props) => props.theme.font.size.ms};
    font-weight: ${(props) => props.theme.font.weight.xl};
    width: 90%;
  }

  ${(props) =>
    props.line &&
    css`
      border-top: 1px solid ${props.theme.color.face.border};
    `}
`

const StyledImg = styled(Img)`
  border-radius: 10px;
  z-index: 1;

  @media (max-width: 991px) {
    width: 100px;
  }
`

const ImgContainer = styled(motion.div)`
  position: relative;
`

const ImgShadow = styled(motion.div)`
  height: 96%;
  width: 96%;
  position: absolute;
  background: ${(props) => props.theme.color.gradient.main};
  border-radius: 29.5px;
  z-index: 1;
`

const OtherPartners = ({ wpId, className }) => {
  let {
    partners: { nodes: partners },
  } = useStaticQuery(graphql`
    {
      partners: allWordpressWpPartner(sort: { fields: date }) {
        nodes {
          ...PreviewPartnersFrag
        }
      }
    }
  `)

  partners = partners.filter((e) => e.wordpress_id !== wpId)

  const [ref, isVisible] = useInView({
    threshold: 0.5,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if (isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <StyledBestBlogs
      className={`d-flex justify-content-end ${
        className ? `${className}` : ``
      }`}
    >
      <InnerBlogs>
        <h2>Meer partners</h2>
        {partners.map((partner, index) => (
          <Post
            line={index !== 0}
            key={partner.wordpress_id}
            className="pb-4 pt-5"
            whileHover="hover"
            initial="rest"
            animate={{}}
          >
            <div className="row flex-nowrap">
              <div className="col-md-4">
                <CustomLink to={partner.path}>
                  <ImgContainer>
                    <StyledImg
                      fluid={
                        partner.acf.preview.image.localFile.childImageSharp
                          .fluid
                      }
                      alt=""
                    />
                  </ImgContainer>
                </CustomLink>
              </div>
              <div className="col-md-8">
                <div className="pt-2">
                  <h3>{partner.title}</h3>
                </div>
                <div className="d-flex pt-2 justify-content-end">
                  <div className="d-flex position-relative">
                    <StyledButtonMinimal to={partner.path} icon="arrow">
                      <p className="mb-0">Bekijk partner</p>
                    </StyledButtonMinimal>
                    <ImgShadow
                      variants={{
                        rest: { right: 1, bottom: 1 },
                        hover: { right: -10, bottom: -10 },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Post>
        ))}
        <BlogShadow
          ref={ref}
          initial={{ right: 0, bottom: 0 }}
          animate={{ right: -40, bottom: -40 }}
          transition={{ delay: 0.3, type: 'spring' }}
        />
      </InnerBlogs>
    </StyledBestBlogs>
  )
}

export default OtherPartners
